import React from 'react'
import { useCalendlyEventListener, InlineWidget } from "react-calendly";



const Calendly = () => {

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });


  
  return (

    <div className="Calendly pt-100 pb-70">
      <InlineWidget url="https://calendly.com/locron/30min" />
    </div>

  );
};

export default Calendly;